import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import { useCreateResource } from '@app/src/api/updateHooks'
import Permissions from '@app/src/auth/permissions'
import LoadingButton from '@app/src/components/LoadingButton'
import { useAccount } from '@app/src/context/AccountContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import useFeatureFlagsByAccessor from '@app/src/hooks/useFeatureFlagsByAccessor'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { FileUpload } from '@mui/icons-material'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

const UploadFiles = () => {
  const queryClient = useQueryClient()
  const [isWaitingToReload, setIsWaitingToReload] = React.useState(false)
  const { response } = useReport()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { mutateAsync: uploadFileAsync, isLoading: isUploading } = useCreateResource<unknown, FormData>()
  const { showErrorNotification } = useErrorNotification()
  const { formatMessage } = useIntl()
  const { hasPermission } = useAccount()
  const { orgOrAccessorHasFeature: suggestedAnswers } = useFeatureFlagsByAccessor({
    featureToggleName: 'suggested-answers',
    enabled: hasPermission(Permissions.TRANSPARENCY_USER),
  })

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const uploadFile = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    await uploadFileAsync(
      { url: endpoints.suggestionFiles, body: formData },
      {
        onSuccess: () => {
          setIsWaitingToReload(true)
          setTimeout(() => {
            queryClient.invalidateQueries(FetchKey.SuggestedResponse)
            queryClient.invalidateQueries(FetchKey.SuggestedFiles)
            queryClient.invalidateQueries(FetchKey.FileRepository)
            setIsWaitingToReload(false)
          }, 2000)
        },
        onError: error =>
          showErrorNotification({
            requestError: error,
            specificFallbackErrorMessage: formatMessage({ id: 'reporting.uploadFileError' }),
          }),
      },
    )
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {
      await uploadFile(selectedFile)
    }
  }

  if (!suggestedAnswers) {
    return null
  }

  if (!response?.id) {
    return null
  }

  return (
    <>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
      <LoadingButton
        startIcon={<FileUpload />}
        onClick={handleFileClick}
        sx={{ px: 2, bgcolor: 'communication.light', color: 'communication.dark' }}
        loading={isUploading || isWaitingToReload}
      >
        Upload files for AI
      </LoadingButton>
    </>
  )
}

export default UploadFiles
