import Permissions from '@app/src/auth/permissions'
import { usePermissions } from '@app/src/auth/permissions/usePermissions'
import { SortableHeaderProps, TableCellWidth } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { Provider } from '@app/src/types/organizations'
import { CheckboxProps, TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

interface SupplierHeaderProps extends SortableHeaderProps {
  selectedProviderIds: Array<number>
  setSelectedProviderIds: React.Dispatch<React.SetStateAction<Array<number>>>
  providersInPage: Provider[]
  showCustomCategories: boolean
  showSpend: boolean
}

const ProviderHeader: React.FC<SupplierHeaderProps> = ({
  toggleSorting,
  activeSorting,
  setSelectedProviderIds,
  providersInPage,
  selectedProviderIds,
  showCustomCategories,
  showSpend,
}) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()

  const isChecked = providersInPage?.every(provider => selectedProviderIds?.includes(provider.id))

  const handleCheckboxChange: CheckboxProps['onChange'] = (_e, checked) => {
    if (checked) {
      setSelectedProviderIds(prev => Array.from(new Set([...prev, ...providersInPage.map(provider => provider.id)])))
    } else {
      setSelectedProviderIds([])
    }
  }

  return (
    <TableRow>
      <TableHeaderCell
        label=""
        checkbox={{ checked: isChecked, onChange: handleCheckboxChange, color: 'primary' }}
        minWidth={TableCellWidth.EMPTY}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.name' })}
        sorting={{ name: 'name', activeSorting, toggleSorting }}
        minWidth={TableCellWidth.LARGE}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.linkStatus' })}
        sorting={{ name: 'linkStatus', activeSorting, toggleSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.country' })}
        sorting={{ name: 'country.name', activeSorting, toggleSorting }}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.provider.contacts' })} />
      <TableHeaderCell label={formatMessage({ id: 'schemas.supplier.ownerUser' })} />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.websiteAddress' })}
        sorting={{ name: 'websiteAddress', activeSorting, toggleSorting }}
      />
      {showCustomCategories && (
        <TableHeaderCell
          label={formatMessage({ id: 'schemas.supplier.customCategories' })}
          minWidth={TableCellWidth.EXTRA_LARGE}
        />
      )}
      {showSpend && (
        <TableHeaderCell
          label={formatMessage({ id: 'schemas.supplier.lastPeriodSpend' }, { period: new Date().getFullYear() })}
        />
      )}
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.vatNumber' })}
        sorting={{ name: 'vatNumber', activeSorting, toggleSorting }}
      />
      {!hasPermission(Permissions.FINANCE_USER) && (
        <TableHeaderCell
          label={formatMessage({ id: 'schemas.provider.activityStatus' })}
          sorting={{ name: 'activityStatus', activeSorting, toggleSorting }}
        />
      )}
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
        sorting={{ name: 'finalRiskRating', activeSorting, toggleSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.provider.priority' })}
        sorting={{ name: 'priority', activeSorting, toggleSorting }}
      />
      {!hasPermission(Permissions.FINANCE_USER) && (
        <TableHeaderCell
          label={formatMessage({ id: 'schemas.provider.providerApprovalStatus' })}
          sorting={{ name: 'providerApprovalStatus', activeSorting, toggleSorting }}
        />
      )}
      {hasPermission(Permissions.SOURCING_USER) && (
        <>
          <TableHeaderCell
            label={formatMessage({ id: 'schemas.provider.tier' })}
            sorting={{ name: 'tier', activeSorting, toggleSorting }}
          />
          <TableHeaderCell
            label={formatMessage({ id: 'schemas.provider.supplierUsage' })}
            sorting={{ name: 'supplierUsage', activeSorting, toggleSorting }}
          />
        </>
      )}
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.registrationNumber' })}
        sorting={{ name: 'registrationNumber', activeSorting, toggleSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.organization.customId' })}
        sorting={{ name: 'customId', activeSorting, toggleSorting }}
      />
      <TableHeaderCell
        label={formatMessage({ id: 'schemas.supplier.createdAt' })}
        sorting={{ name: 'createdAt', activeSorting, toggleSorting }}
      />
    </TableRow>
  )
}

export default ProviderHeader
